import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faEnvelopeCircleCheck,
    faCircle,
    faCheck,
    faXmark,
    faPlus,
    faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
//import emailjs from 'emailjs-com';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import axios from "axios";
import { NumericFormat } from "react-number-format";
//import { Helmet, HelmetProvider } from "react-helmet-async";
import confetti from "canvas-confetti";
import { RevealOnScroll } from "./RevealOnScroll";
import { ErrorMessage } from "@hookform/error-message";
import { BotonEnviando } from "./BotonEnviando";
import { Moneda } from "./Moneda";

const primeraLetraMayuscula = (cadena) => {
    return cadena
        .charAt(0)
        .toUpperCase()
        .concat(cadena.substring(1, cadena.length));
};

const Checkbox = ({ label, value, onChange }) => {
    return (
        <label>
            <input type="checkbox" checked={value} onChange={onChange} />
            {label}
        </label>
    );
};

const Checkbox1 = ({ label, value, onChange }) => {
    return (
        <div class="custom-control custom-switch">
            <input
                type="checkbox"
                class="custom-control-input"
                id="customSwitch1"
                checked={value}
                onChange={onChange}
            />

            <label class="custom-control-label" for="customSwitch1"></label>
        </div>
    );
};
export const Carrito = ({
    checked,
    setChecked,
    ObjectLength,
    envioFlete,
    getEnvioFlete,
    MiCarrito,
    setMiCarrito,
    MiCarritoTOTAL,
    getMiCarrito,
    formatoPrecio,
    moneda,
    setMoneda,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [totalCarrito, setTotalCarrito] = useState(0);
    var [estadoCarrito, setEstadoCarrito] = useState(0);
    const ref = useRef();
    const [ultimoId, setultimoId] = useState(0);

    const handleChange = () => {
        setChecked(!checked);
    };

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const confites = () => {
        setTimeout(() => {
            confetti({
                particleCount: 60,
                startVelocity: 22,
                spread: 90,
                /*
                        origin: {
                        x: Math.random(),
                        // since they fall down, start a bit higher than random
                        y: Math.random() - 0.2
                        }
                        angle: 120,
                        */
            });
        }, 1000);
    };

    const getEstadoCarrito = (nuevo) => {
        //alert(nuevoNombre);
        setEstadoCarrito(nuevo);

        window.scrollTo({
            top: 0,
            behavior: "smooth", // Opcionalmente, puedes cambiar a 'auto' para un desplazamiento instantáneo
        });
        //window.scrollTo(0, 0);
    };

    // Function that displays a success toast on bottom right of the page when form submission is successful
    const toastifySuccess = () => {
        toast("Form sent!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            className: "submit-feedback success",
            toastId: "notifyToast",
        });
    };

    const onSubmit = async (e) => {
        let info;
        const venta = ref.current;
        //e.preventDefault();
        console.log(venta.nombre.value);
        if (checked == true) {
            info = {
                nombre: venta.nombre.value,
                apellido: venta.apellido.value,
                email: venta.email.value,
                telefono: venta.telefono.value,
                venta: JSON.stringify(MiCarrito),
                moneda: moneda,
                direccion: venta.direccion.value,
                numero: venta.numero.value,
                localidad: venta.localidad.value,
                piso: venta.piso.value,
                departamento: venta.departamento.value,
            };
        } else {
            info = {
                nombre: venta.nombre.value,
                apellido: venta.apellido.value,
                email: venta.email.value,
                telefono: venta.telefono.value,
                venta: JSON.stringify(MiCarrito),
                moneda: moneda
            };
        }
        //console.log(venta.direccion.value);
        await axios
            .post(process.env.REACT_APP_URL_API + "/ventas/", info)
            .then(({ data }) => {
                //alert(data);
                //console.log(data);
                reset();
                //toastifySuccess();
                getMiCarrito([]);
                setultimoId(data);
                getEstadoCarrito(2);
                confites();
                setIsLoading(false);

                //const ultimoId = ;
            })
            .catch(({ data }) => toast.error(data));
    };

    const deleteByIndex = (index) => {
        setMiCarrito((oldValues) => {
            return oldValues.filter((_, i) => i !== index);
        });
    };

    const sumar = (precio, cantidad) => {
        //alert("yoyo");
        return precio * cantidad;
    };

    const updateByIndex = (index) => {
        //alert("yoy");

        const newState = MiCarrito.map((obj, index1) => {
            // 👇️ if id equals 2, update country property
            if (index1 === index) {
                return {
                    ...obj,
                    cantidad: obj.cantidad + 1,
                    precioTOT: sumar(obj.precio, obj.cantidad + 1),
                };
            }

            // 👇️ otherwise return the object as is
            return obj;
        });

        setMiCarrito(newState);
    };

    const updateByIndex1 = (index) => {
        //alert("yoy");

        const newState = MiCarrito.map((obj, index1) => {
            // 👇️ if id equals 2, update country property
            if (index1 === index) {
                if (obj.cantidad == 1) {
                    //alert("0");
                } else {
                    return {
                        ...obj,
                        cantidad: obj.cantidad - 1,
                        precioTOT: sumar(obj.precio, obj.cantidad - 1),
                    };
                }
            }

            // 👇️ otherwise return the object as is
            return obj;
        });

        setMiCarrito(newState);
    };

    /*
    const sumaTotal = () => {
        
                    if (moneda === "AR") {
                        //setTotalCarrito(1000)//totalCarrito + MiCarro.precio.ar
                        sumaTotal(100)
                    }
                    if (moneda === "USD") {
                        //setTotalCarrito(totalCarrito + MiCarro.precio.usd)
                    }
                    setTotalCarrito(n)

    };*/


    const sumaTotal = () => {
        let total = 0;

        if (moneda === "AR") {
            MiCarrito.map((MiCarro, index) => {
                total = total + Number(MiCarro.precio.ar)

            })
            console.log("!!!!!!!!!!!!!!!total")
            console.log(total)
            setTotalCarrito(total)
        }
        else if (moneda === "USD") {
            MiCarrito.map((MiCarro, index) => {
                total = total + Number(MiCarro.precio.usd)

            })
            console.log("!!!!!!!!!!!!!!!total")
            console.log(total)
            setTotalCarrito(total)
        }
    }



    useEffect(() => {
        sumaTotal();
    }, [MiCarrito, moneda]);

    return (
        <>
            <div className=" bg-[#EEF2FF] dark:bg-[#070a1c] margen1">
                {/*estadoCarrito*/}
                <RevealOnScroll>
                    <h3 className="text-center pb-4 titulo1 mb-3">Carro de compra</h3>
                </RevealOnScroll>
                <div className="hidden">steps 3 cuadradtiso chicos</div>

                {estadoCarrito === 0 && (
                    <div className="pl-3 pr-3 pl-lg-5 pr-lg-5">
                        {ObjectLength() == 0 ? (
                            <div class="h-[300px]">
                                <div className="flex justify-center m-5 ">
                                    <div className="text-center fondo9 border bordes p-3 pl-4 pr-4 p-1 bg-[#F8FAFF] dark:bg-[#070c21]   ">
                                        El carro de compras está vacio.
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <Moneda moneda={moneda} setMoneda={setMoneda} />

                                <div className="bordes text-black bg-[#F8FAFF]  dark:text-[#e5e7ee] dark:bg-[#070c21] w-100">
                                    <div className=" hidden lg:grid grid-cols-3 gap-4  borde-bottom p-3">
                                        <div className="font-bold text-center">Plan</div>
                                        {/*
                                               <div className=''><span className='font-weight-bold'>Cantidad</span></div>
                                         */}

                                        <div className="font-bold text-right">Precio</div>
                                        <div className="font-bold text-center">Borrar</div>
                                    </div>

                                    {MiCarrito.map((MiCarro, index) => {
                                        /*
                                                                                                            if (moneda === "AR") {
                                                                                                                //setTotalCarrito(1000)//totalCarrito + MiCarro.precio.ar
                                                                                                                sumaTotal(100)
                                                                                                            }
                                                                                                            if (moneda === "USD") {
                                                                                                                //setTotalCarrito(totalCarrito + MiCarro.precio.usd)
                                                                                                            }
                                                                
                                                                */
                                        return (
                                            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 borde-bottom p-3 text-center lg:text-left ">

                                                <div className="flex justify-center  items-center  ">
                                                    <div className="mr-2">
                                                        <img
                                                            src={
                                                                process.env.REACT_APP_URL_FOLDER +
                                                                "/./imagenes/productos/" +
                                                                MiCarro.id +
                                                                "/" +
                                                                MiCarro.imagen
                                                            }
                                                            className="img-plan"
                                                            alt="..."
                                                        />
                                                    </div>
                                                    <div>{primeraLetraMayuscula(MiCarro.nombre)}</div>
                                                </div>
                                                {/*
                                                
                                                               <div className='hidden'>
                                                    <div className='carrito-titulos'><span className='font-weight-bold'>Cantidad</span></div>
                                                    <div>
                                                        <div className='flex justify-center lg:justify-start'>
                                                            <button className='btn btn-carrito ' onClick={() => updateByIndex1(index)}><FontAwesomeIcon icon={faMinus} /></button>

                                                            <div className='btn-carrito-cantidad'> {MiCarro.cantidad} </div>

                                                            <button className='btn  btn-carrito ' onClick={() => updateByIndex(index)}><FontAwesomeIcon icon={faPlus} /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                */}
                                                <div className="">
                                                    <div className="carrito-titulos font-bold">
                                                        Precio
                                                    </div>
                                                    <div className="lg:text-right">
                                                        {/*MiCarro.precio*/}$
                                                        {moneda === "AR" && <>{formatoPrecio(MiCarro.precio.ar)}</>}
                                                        {moneda === "USD" && <>{formatoPrecio(MiCarro.precio.usd)}</>}
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="carrito-titulos font-bold ">
                                                        <span className="font-weight-bold">Borrar</span>
                                                    </div>
                                                    <div className="lg:text-center">
                                                        <button
                                                            className="btn  btn-carrito "
                                                            onClick={() => deleteByIndex(index)}>
                                                            <FontAwesomeIcon icon={faXmark} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}

                                    <div className="font-black  lg:grid lg:grid-cols-3 gap-4 p-3">
                                        <div className=" lg:col-start-1 lg:col-end-2 text-center lg:text-right">
                                            <div className="lg:hidden">TOTAL</div>
                                        </div>
                                        <div className="text-center lg:text-right">
                                            <div className="">
                                                <span className="hidden lg:inline">TOTAL </span>$
                                                {formatoPrecio(totalCarrito)} {moneda === "AR" && <>ARS</>}{moneda === "USD" && <>USD</>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center mt-5">
                                    <button
                                        type="button"
                                        class="boton1"
                                        onClick={() => {
                                            getEstadoCarrito(1);
                                        }}>
                                        Continuar compra
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {estadoCarrito === 1 && (
                    <div className="pl-3 pr-3 pl-lg-5 pr-lg-5">
                        <h3 className="text-left ">Datos de contacto</h3>

                        <div className="contactForm mt-4">
                            <form
                                id="contact-form"
                                onSubmit={handleSubmit((data) => {
                                    onSubmit(data);
                                    setIsLoading(true);
                                })}
                                noValidate
                                ref={ref}>
                                <div className="">
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                                        <div>
                                            <label className="block text-sm font-medium leading-6">
                                                Nombre
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="nombre"
                                                    {...register("nombre", { required: "requerido" })}
                                                    className={
                                                        errors.nombre ? "formularioError" : "formulario1"
                                                    }
                                                    placeholder="Laura"
                                                    role="presentation"
                                                    autoComplete="off"></input>
                                            </div>

                                            <ErrorMessage
                                                errors={errors}
                                                name="nombre"
                                                render={({ message }) => (
                                                    <p className="merror hidden">{message}</p>
                                                )}
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium leading-6">
                                                Apellido
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="apellido"
                                                    {...register("apellido", {
                                                        required: "requerido",
                                                    })}
                                                    className={
                                                        errors.apellido ? "formularioError" : "formulario1"
                                                    }
                                                    placeholder="Lopez"
                                                    role="presentation"
                                                    autoComplete="off"></input>
                                            </div>
                                            <ErrorMessage
                                                errors={errors}
                                                name="apellido"
                                                render={({ message }) => (
                                                    <p className="merror hidden">{message}</p>
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="grid  grid-cols-1 lg:grid-cols-2 gap-4  mb-4 ">
                                        <div className="">
                                            <label className="block text-sm font-medium leading-6">
                                                Teléfono
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="telefono"
                                                    {...register("telefono", {
                                                        required: "requerido",
                                                    })}
                                                    className={
                                                        errors.telefono ? "formularioError" : "formulario1"
                                                    }
                                                    placeholder="11 2222 3333"
                                                    role="presentation"
                                                    autoComplete="off"></input>
                                            </div>
                                            <ErrorMessage
                                                errors={errors}
                                                name="telefono"
                                                render={({ message }) => (
                                                    <p className="merror hidden">{message}</p>
                                                )}
                                            />
                                        </div>
                                        <div className="">
                                            <label className="block text-sm font-medium leading-6">
                                                Email
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    {...register("email", {
                                                        required: "requerido",
                                                        pattern:
                                                            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                    })}
                                                    className={
                                                        errors.email ? "formularioError" : "formulario1"
                                                    }
                                                    placeholder="LauraLopez@gmail.com"
                                                    role="presentation"
                                                    autoComplete="off"></input>
                                            </div>

                                            <ErrorMessage
                                                errors={errors}
                                                name="email"
                                                render={({ message }) => (
                                                    <p className="merror hidden">{message}</p>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-3 ">
                                    <div className="order-last lg:order-first text-center lg:text-right">
                                        <button
                                            type="submit"
                                            onClick={() => {
                                                getEstadoCarrito(0);
                                            }}
                                            class="boton1">
                                            Volver
                                        </button>
                                    </div>

                                    <div className="text-center lg:text-left ">
                                        <button
                                            className="boton1"
                                            disabled={isLoading}
                                            type="submit">
                                            {isLoading ? (
                                                <BotonEnviando
                                                    texto={"Realizando compra"}></BotonEnviando>
                                            ) : (
                                                "Realizar compra"
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )}

                {estadoCarrito === 2 && (
                    <div className="text-center pl-3 pr-3 pl-lg-5 pr-lg-5">
                        <div className="mt-5">
                            <span className="fa-layers fa-4x">
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    color="green"
                                    transform="grow-5"
                                />
                                <FontAwesomeIcon icon={faCheck} inverse />
                            </span>
                        </div>

                        <div className="comprastes font-bold mt-5 mb-5">
                            ¡Compra realizada!
                        </div>

                        <div className="text-sm">
                            <div>Te enviamos un email con todos los datos de tu compra.</div>
                            <div className="hidden">
                                Tu numero de identificación es{" "}
                                <span className="font-weight-bold">#{ultimoId}</span>.
                            </div>
                            <div>Podes llamarnos por teléfono al 54 9 11 2587 0303.</div>
                            <div className="">En breve nos pondremos en contacto.</div>
                        </div>

                        <div className="mt-2">
                            <Link to={process.env.REACT_APP_URL_FOLDER + "/"}>
                                <button
                                    type="button"
                                    class="boton1"
                                    onClick={() => {
                                        getEstadoCarrito(0);
                                    }}>
                                    Finalizar
                                </button>
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

/*

        <div onClick={() => updateByIndex(1)} >+</div>

                            {MiCarrito.map(x => <div>{x.nombre}</div>)
                            }
*/
