import React from 'react'
import { Link } from 'react-router-dom'
export const Opps = () => {
    return (
        <section class="grid min-h-full place-items-center bg-dark px-6 py-24 sm:py-32 lg:px-8 fondo3">
            <div class="text-center">
                <p class="text-base font-semibold text-blue-600">404</p>
                <h1 class="mt-4 text-3xl font-bold tracking-tight text-white-900 sm:text-5xl">Página no encontrada</h1>
                <p class="mt-6 text-base leading-7 text-gray-100">Lo sentimos, no pudimos encontrar la página que estás buscando.</p>
                <div class="mt-10 flex items-center justify-center gap-x-6">
                    <Link to="/" class="boton1">Regresar al inicio</Link>
                    <Link to="/#contacto" class="hidden mt-5 text-sm font-semibold text-gray-900">Contactar al soporte <span aria-hidden="true">&rarr;</span></Link>
                </div>
            </div>
        </section>
    )
}