import { Outlet, Link } from "react-router-dom";

export const Layaut = () => {
  return (
    <>
      <div >
        <Outlet></Outlet>
      </div>
    </>
  )
}

