import { Fragment, useEffect, useState, React } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Routes, Route } from "react-router-dom";
import { Layaut } from "./components/Layaut";
import { Productos } from "./components/Productos";
import { Home } from "./components/Home";
import { Opps } from "./components/Opps";
import "./estilo/estilo.css";
//import './estilo/estilo-bootstrap.css';
import { Outlet, Link } from "react-router-dom";
import { Carrito } from "./components/Carrito";
import { Contacto } from "./components/Contacto";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faCartShopping,
  faPhone,
  faLocationDot,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import ReactWhatsappButton from "react-whatsapp-button";
import logo from "./imagenes/powerbit.svg";
import RegisterForm from "./components/RegisterForm";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import { Nav } from "./components/Nav";
import confetti from "canvas-confetti";
import { NumericFormat } from "react-number-format";

function App() {
  const [productos, setProductos] = useState([]);
  const [preciosVariables, setPreciosVariables] = useState([]);
  const [links, setLinks] = useState([]);
  const [productosCategorias, setProductosCategorias] = useState([]);
  const [onEdit, setOnEdit] = useState(null);
  const [onEdit1, setOnEdit1] = useState(null);
  const [nombre1, setNombre] = useState(0);
  const [filtro, setFiltro] = useState(0);
  const [filtroOrden, setFiltroOrden] = useState("Menor precio");
  const [ordenar, setOrdenar] = useState("nombre");
  const [categoria, setCategoria] = useState({ id: 1, nombre: "Ceramicos" });
  const [ProductoXid, setProductoXid] = useState([]);

  //const [idCategoria, setIdCategoria] = useState(3);
  const [parmsIdProducto, setParmsIdProducto] = useState(null);
  const [MiCarrito, setMiCarrito] = useState([]);
  const [MiCarritoTOTAL, setMiCarritoTOTAL] = useState(0);
  const [productosDescatados, setProductosDestacados] = useState([]);
  const [sucursales, setSucursales] = useState(0);
  const [envioFlete, setEnvioFlete] = useState(false);
  const [checked, setChecked] = useState(false);

  const location = useLocation();
  const lastHash = useRef("");
  const [dark, setDark] = useState(false);

  const Redondeo = (precio) => {
    let redondeo = 0;
    if (moneda == "AR") {
      redondeo = Math.ceil(precio / 100) * 100;
    }
    if (moneda == "USD") {
      redondeo = Math.ceil(precio);
    }
    //let formato = redondeo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    //return formato;
    return redondeo;
  };

  /*
    const formatoPrecio = (precio) => {
  
      //let formato = precio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      //return formato;
      return precio
    }*/

  const formatoPrecio = (precio) => {
    if (precio !== undefined && precio !== null) {
      let formato = precio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return formato;
    } else {
      return ""; // O cualquier valor predeterminado que prefieras
    }
  };

  const getEnvioFlete = (nuevo) => {
    //alert(nuevoNombre);
    setEnvioFlete(nuevo);
  };
  const getMiCarritoTOTAL = (nuevo) => {
    //alert(nuevoNombre);
    var tot = 0;
    MiCarrito.map((MiCarro, index) => (tot = tot + MiCarro.precioTOT));
    setMiCarritoTOTAL(tot);
  };

  const getMiCarrito = (nuevo) => {
    //alert(nuevoNombre);
    setMiCarrito(nuevo);
    console.log(MiCarrito);
  };

  const getCategoria = (nuevoNombre) => {
    //alert(nuevoNombre);
    setCategoria(nuevoNombre);
  };
  const getProductos = async () => {
    console.log("###getProductos 1");
    try {
      const res = await axios.get(
        process.env.REACT_APP_URL_API +
        "/productos/" +
        categoria.id +
        "/" +
        filtroOrden
      );
      //alert("aqui2");
      console.log("$$$$$");
      console.log(res.data);
      setProductos(res.data); //.sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
    } catch (error) {
      toast.error(error);
    }
  };
  const getPreciosVariables = async () => {
    console.log("###getPreciosVariables 2");
    try {
      const res = await axios.get(
        process.env.REACT_APP_URL_API + "/preciosVariables/"
      );
      //alert("aqui2");
      console.log("preciosvariables");
      console.log(res.data);
      setPreciosVariables(res.data); //.sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
    } catch (error) {
      toast.error(error);
    }
  };
  const getProductosDestacados = async () => {
    //alert("aqui prodcutps lista");
    try {
      const res = await axios.get(
        process.env.REACT_APP_URL_API + "/productosDestacados/"
      );
      //alert("aqui2");
      setProductosDestacados(res.data); //.sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
    } catch (error) {
      toast.error(error);
    }
  };

  const ObjectLength = () => {
    var length = 0;
    for (var key in MiCarrito) {
      if (MiCarrito.hasOwnProperty(key)) {
        ++length;
      }
    }
    return length;
  };

  const [moneda, setMoneda] = useState("AR");
  const [dolarMep, setDolarMep] = useState(null);
  const [precioPlan1, setPrecioPlan1] = useState({});
  const [precioPlan2, setPrecioPlan2] = useState({});
  const [precioPlan3, setPrecioPlan3] = useState({});

  const getDolarMep = async () => {
    //alert("dolarmep")
    console.log("###getDolarMep 3");
    try {
      const res = await axios.get("https://criptoya.com/api/dolar");
      console.log("status");
      console.log(res.status);

      if (res.status == 200) {
        //correcta
        console.log(".");
        console.log(res.data);
        console.log(res.data.mep.al30);
        console.log(res.data.mep.al30["24hs"].price);
        let x = Number(res.data.mep.al30["24hs"].price);
        let y = Number(res.data.mep.al30["24hs"].variation);
        let z = Number(x);
        setDolarMep(z);
      }
      if (res.status == 201) {
      }
    } catch (error) {
      toast.error(error);
    }
  };

  //https://criptoya.com/api/dolar

  const PreciosPlanes = async () => {
    console.log("###PreciosPlanes 4");
    console.log("preciosVariables");
    console.log(preciosVariables);

    let webHosting = preciosVariables[3]["precio"];
    let dominio1año = preciosVariables[2]["precio"];
    let sueldoMioConGanancia = preciosVariables[4]["precio"];
    let ssl = preciosVariables[5]["precio"];
    let cloudHosting = preciosVariables[0]["precio"];
    let anuncioPatrocinadoGoogle = preciosVariables[1]["precio"];
    //console.log("!!!")
    //console.log(webHosting);
    //console.log(formatoPrecio(webHosting));

    //PLAN1
    let precioTotalARS = parseFloat(0);
    let precioTotalUSD = parseFloat(0);
    setPrecioPlan1({ ar: [precioTotalARS], usd: [precioTotalUSD] });

    //PLAN 2
    //ARS
    precioTotalARS =
      webHosting + dominio1año + sueldoMioConGanancia / 2 / 2 + ssl;
    console.log("precioTotal AR");
    console.log(precioTotalARS);
    //USD
    console.log("dolar mep");
    console.log(dolarMep);

    precioTotalUSD = Number(precioTotalARS) / dolarMep;
    console.log("precioTotal USD");
    console.log(precioTotalUSD);
    //FORMATEO
    precioTotalARS = Redondeo(precioTotalARS);
    precioTotalUSD = Redondeo(precioTotalUSD);
    setPrecioPlan2({ ar: [precioTotalARS], usd: [precioTotalUSD] });

    //PLAN3
    precioTotalUSD = 0;
    precioTotalARS = 0;
    //ARS
    precioTotalARS =
      cloudHosting +
      dominio1año +
      sueldoMioConGanancia / 2 +
      ssl +
      anuncioPatrocinadoGoogle;
    console.log("precioTotal AR");
    console.log(precioTotalARS);
    //USD
    precioTotalUSD = Number(precioTotalARS) / dolarMep;
    console.log("precioTotal USD");
    console.log(precioTotalUSD);
    //FORMATEO
    precioTotalARS = Redondeo(precioTotalARS);
    precioTotalUSD = Redondeo(precioTotalUSD);
    setPrecioPlan3({ ar: [precioTotalARS], usd: [precioTotalUSD] });
  };

  const confites = () => {
    setTimeout(() => {
      confetti({
        spread: 2500,
        origin: { y: 0 },
        particleCount: 200,
        startVelocity: 22,
        angle: 120,
        //spread: 90,
        // origin: { x: 1 }
        /*
            origin: {
            x: Math.random(),
            // since they fall down, start a bit higher than random
            y: Math.random() - 0.2
            }
            angle: 120,
            */
      });
    }, 1000);
  };

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
        lastHash.current = "";
      }, 0);
    }
  }, [location]);

  async function ejecutarFunciones() {
    await getProductos(); // Espera a que se complete
    await getPreciosVariables(); // Luego, ejecuta
    await getDolarMep();
  }

  useEffect(() => {
    if (dolarMep !== null) {
      PreciosPlanes();
    }
  }, [dolarMep, moneda]);

  useEffect(() => {
    ejecutarFunciones();
  }, []);

  useEffect(() => {
    ejecutarFunciones();
  }, [moneda]);

  useEffect(() => {
    getMiCarritoTOTAL();
    console.log("+++");
    console.log(MiCarrito);
    //getProductos();
    //getProductosId();
  }, [MiCarrito]); //setProductos

  return (
    <>
      <div className="bg-[#e4ebf6] dark:bg-black w-full min-h-screen">
        <div className="2xl:container bg-white mx-auto text-[#000] dark:text-[#c3c3cc] ">
          <Nav
            MiCarrito={MiCarrito}
            ObjectLength={ObjectLength}
            dark={dark}
            setDark={setDark}
          />
          <main>
            <Routes>
              <Route path="/" element={<Layaut />}>
                <Route
                  path={process.env.REACT_APP_URL_FOLDER + "/"}
                  element={
                    <Home
                      productos={productosDescatados}
                      getProductos={getProductosDestacados}
                      productosCategorias={productosCategorias}
                      getCategoria={getCategoria}
                      categoria={categoria}
                      setMiCarrito={setMiCarrito}
                      moneda={moneda}
                      setMoneda={setMoneda}
                      dolarMep={dolarMep}
                      precioPlan1={precioPlan1}
                      precioPlan2={precioPlan2}
                      precioPlan3={precioPlan3}
                      formatoPrecio={formatoPrecio}
                    />
                  }
                />
                <Route
                  path={process.env.REACT_APP_URL_FOLDER + "/carrito"}
                  element={
                    <Carrito
                      checked={checked}
                      setChecked={setChecked}
                      ObjectLength={ObjectLength}
                      envioFlete={envioFlete}
                      getEnvioFlete={getEnvioFlete}
                      MiCarrito={MiCarrito}
                      setMiCarrito={setMiCarrito}
                      MiCarritoTOTAL={MiCarritoTOTAL}
                      getMiCarrito={getMiCarrito}
                      formatoPrecio={formatoPrecio}
                      moneda={moneda}
                      setMoneda={setMoneda}
                    />
                  }
                />
                <Route path="*" element={<Opps />} />
              </Route>
            </Routes>
          </main>

          <footer className="footer bg-[#F6F7F8] text-black dark:bg-[#050610] dark:text-[#8da1ba] margen1 bg-neutral text-neutral-content">
            <div className=" gap-4">
              <div>
                <div className="flex justify-center">
                  <div>
                    <img
                      class="block dark:hidden h-8 w-auto"
                      src={
                        process.env.REACT_APP_URL_FOLDER +
                        "/./imagenes/powerbit-light.svg"
                      }
                      alt="PowerBit Logo"
                    />
                    <img
                      class="hidden dark:block h-8 w-auto"
                      src={
                        process.env.REACT_APP_URL_FOLDER +
                        "/./imagenes/powerbit-dark.svg"
                      }
                      alt="PowerBit Logo"
                    />
                  </div>
                </div>
                <p className="text-center font-semibold dark:font-[10] pie_rights">
                  © {new Date().getFullYear()}. Todos los derechos reservados.
                </p>
              </div>
            </div>
          </footer>
        </div>
        <ReactWhatsappButton
          countryCode="54"
          phoneNumber="91125870303"
          animated
          style={
            {
              /*


  phoneNumber="91125870303"

          backgroundColor: "#00a4f5",
          right: "unset",
          left: "10px",*/
            }
          }
          message="Hola, quiero hacer una pregunta..."
        />
        {/*animated*/}
        <ToastContainer
          autoClose={3000}
          position={toast.POSITION.BOTTOM_RIGHT}
        />
      </div>
    </>
  );
}
export default App;
