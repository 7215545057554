

export const Moneda = ({ moneda, setMoneda }) => {

    const cambiaMoneda = (moneda) => {
        console.log(moneda);
        setMoneda(moneda);
    };

    return (

        <section >
            <div class="w-[220px] pb-5">
                <div class="">
                    <label
                        for="country"
                        class="registro__contenedorForm__celda__titulo dark:!text-white !text-black">
                        Moneda
                    </label>
                    <div class="registro__contenedorForm__celda__titulo content-select ">
                        <select
                            name="moneda"
                            onChange={(e) => {
                                cambiaMoneda(e.target.value);
                            }}
                            className="input__sexo registro__contenedorForm__celda__contenedor__input moneda">
                            <option value="AR" selected={moneda === "AR"}>Peso argentino</option>
                            <option value="USD" selected={moneda === "USD"}>Dólar estadounidense</option>
                        </select>
                    </div>
                </div>
            </div>
        </section>
    )
}