import React from "react";
import { useParams } from "react-router";
import { useEffect, useRef, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { CheckIcon } from "@heroicons/react/24/solid";
import { Moneda } from "./Moneda";

export const ProductosListar = ({
    productos,
    getProductos,
    getIdProducto1,
    getCategoria,
    rango1,
    rango2,
    setMiCarrito,
    moneda,
    setMoneda,
    dolarMep,
    precioPlan1,
    precioPlan2,
    precioPlan3,
    formatoPrecio
}) => {
    const { idProducto } = useParams();





    const isObjetoVacio = (obj) => {
        return Object.values(obj).some(
            (value) => value === undefined || value === null || value.length === 0
        );
    };

    const handleClick = (nombre, precio, id, imagen, unidad) => {
        //alert(precio);
        setMiCarrito((current) => [
            ...current,
            {
                id: id,
                nombre: nombre,
                imagen: imagen,
                precio: precio,
                cantidad: 1,
                precioTOT: parseInt(0),//precio.replace(".", ""))
                unidad: unidad,
            },
        ]);
    };

    const primeraLetraMayuscula = (cadena) => {
        return cadena
            .charAt(0)
            .toUpperCase()
            .concat(cadena.substring(1, cadena.length));
    };

    //const productos1 = productos['productos'];
    console.log("////");
    console.log(productos);
    useEffect(() => {
        getProductos();
    }, []);

    useEffect(() => {
        // Uso de la función en un condicional
        if (!isObjetoVacio(precioPlan1)) {
            //alert("hello");
            console.log("!!!!!!!!!!!!!!!!!!!!!!!precioPlan1")
            console.log(precioPlan1)
        } else {
            console.log("!!!!!!!!!!!!!!!!!!!!!!!precioPlan1:El objeto está vacío o contiene datos inválidos.");
        }
    }, [precioPlan1]);

    let productosPaginacion = productos.slice(rango1, rango2);

    return (
        <>

            {!isObjetoVacio(precioPlan1) &&
                !isObjetoVacio(precioPlan2) &&
                !isObjetoVacio(precioPlan3) > 0 && (
                    <section
                        id="planes"
                        className="planes margen1 bg-[#EEF2FF] dark:bg-[#141828]">
                        <h1 className=" text-center titulo1 mb-4">Planes</h1>

                        <Moneda moneda={moneda} setMoneda={setMoneda} />

                        <div className="grid gap-7 grid-cols-1 lg:grid-cols-3 ">
                            {productosPaginacion.map((producto) => {
                                let precio;
                                if (producto.id === 1) {
                                    precio = precioPlan1;
                                } else if (producto.id === 2) {
                                    precio = precioPlan2;
                                } else if (producto.id === 3) {
                                    precio = precioPlan3;
                                }
                                console.log("precio");
                                console.log(precio);
                                return (
                                    <div className="rounded-2xl bg-[#F8FAFF] dark:bg-[#070c21] py-10 text-center ring-1 ring-inset ring-[#0962ba] justify-center py-16">
                                        <div className="mx-auto  px-8">
                                            <div className="flex justify-center  items-center">
                                                <div>
                                                    <img
                                                        src={
                                                            process.env.REACT_APP_URL_FOLDER +
                                                            "/./imagenes/productos/" +
                                                            producto.id +
                                                            "/" +
                                                            JSON.parse(producto.imagenes)[0]
                                                        }
                                                        class="img-plan"
                                                        alt="..."
                                                    />
                                                </div>
                                                <div className="pl-2">
                                                    <p className="text-base font-semibold text-white-600 text-lg">
                                                        {primeraLetraMayuscula(producto.nombre)}
                                                    </p>
                                                </div>
                                            </div>

                                            <p className="mt-6 flex items-baseline justify-center gap-x-2">
                                                <span className="text-5xl font-bold tracking-tight text-white-900">
                                                    ${moneda === "AR" && <>{formatoPrecio(precio.ar)}</>}
                                                    {moneda === "USD" && <>{formatoPrecio(precio.usd)}</>}
                                                </span>
                                                <span className="text-2xl font-bold tracking-tight text-white-900">
                                                    {moneda === "AR" && <>ARS</>}
                                                    {moneda === "USD" && <>USD</>}
                                                </span>
                                            </p>

                                            <Link
                                                to={process.env.REACT_APP_URL_FOLDER + "/carrito"}
                                                onClick={() =>
                                                    handleClick(
                                                        producto.nombre,
                                                        precio,
                                                        producto.id,
                                                        JSON.parse(producto.imagenes)[0],
                                                        producto.nombre_unidad
                                                    )
                                                }
                                                className="boton2">
                                                {producto.id == 1 ? <>Solicitar</> : <>Comprar</>}
                                            </Link>

                                            {producto.id == 2 && (
                                                <>
                                                    <p className="text-sm font-semibold text-white-600  mt-6  text-left dark:font-[10]">
                                                        Comprá tu sitio web y gana presencia en internet.
                                                    </p>
                                                </>
                                            )}
                                            {producto.id == 3 && (
                                                <>
                                                    <p className="text-sm font-semibold text-white-600 mt-6   text-left dark:font-[10]">
                                                        Comprá tu tienda virtual y genera más ventas con
                                                        publicidad.
                                                    </p>
                                                </>
                                            )}

                                            <ul className=" mt-6  text-sm text-white-600 list-inside list-disc text-left">
                                                {JSON.parse(producto.descripcion).map((item) => (
                                                    <>
                                                        <li className="list-none pb-4 flex">
                                                            <div className="flex">
                                                                {producto.id !== 1 && (
                                                                    <div className="flex-none w-5 mr-3">
                                                                        {item[0] == 1 ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 20 20"
                                                                                fill="currentColor"
                                                                                className="text-[#274dd8]">
                                                                                <path
                                                                                    fillRule="evenodd"
                                                                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                                                    clipRule="evenodd"
                                                                                />
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 20 20"
                                                                                fill="currentColor"
                                                                                aria-hidden="true"
                                                                                class="text-[#cb1515]">
                                                                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                                                            </svg>
                                                                        )}
                                                                    </div>
                                                                )}

                                                                <div>
                                                                    <div>
                                                                        <p className="font-semibold dark:font-[10]">
                                                                            {item[1]}
                                                                            {/*JSON.parse(item).map((x) =>
                                                                <div>{x}</div>

                                                             )*/}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </>
                                                ))}
                                            </ul>

                                            {producto.id == 3 && (
                                                <>
                                                    <br />

                                                    <a
                                                        href="https://www.tiendabrando.com.ar"
                                                        target="_blank"
                                                        className="underline font-semibold underline underline-offset-4 dark:font-medium decoration-[#274dd8]  dark:hover:decoration-[#fff] hover:decoration-[#000] text-sm text-white-600">
                                                        Ver Tienda virtual
                                                    </a>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </section>
                )}
        </>
    );
};
